import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Hero from "../components/hero";
import PageHeader from "../components/page-header";
import Seo from "../components/seo";
import Awards from "../content-components/awards";
import History from "../content-components/history";
import Layout from "../layouts/index";
const WhoWeAre = ({ data }) => {
  if (!data) return null;

  const awards = data.allPrismicAwards.nodes;

  return (
    <Layout>
      <Seo title="Who we are" />

      <PageHeader title="Who we are" />

      <section className="block grid-container narrow" aria-labelledby="whoWeAreIntro">
        <h2 id="whoWeAreIntro" className="show-for-sr">
          Introduction
        </h2>
        <p>
          We're one of the largest lenders in the UK used car finance market. We’ve specialised in motor finance for
          over 40 years, and are recognised as one of the best car finance providers in the industry.  We’re part of the
          Aldermore Group, which includes Aldermore Bank plc. Aldermore offers a range of personal and business finance,
          and savings options.
        </p>
        <StaticImage src="../assets/images/aldermore-motonovo-logos.png" />
        <p>
          Here at MotoNovo, we're proud of our culture and put trust, integrity, and teamwork at the heart of what we
          do. It’s what makes us unique, and is part of our success.
        </p>
        <p>
          Our teams are a diverse bunch who have several things in common - we're committed and motivated, but are also
          well rewarded and know how to have fun! When you join us, you'll become part of a business which values you as
          an individual and believes that you will make an incredible contribution to the growth of our business.
        </p>
        <p>
          Located in One Central Square, at the heart of Cardiff City Centre, we've provided our employees with a
          first-class working environment, designed to enhance creativity. As we grow, we're on the look-out for more
          talented and ambitious individuals to join our enthusiastic team, and help us in continuing to drive forward
          through innovation and change.
        </p>
      </section>

      <History />

      <Hero image={data.whoWeAreHero} alt="Motonovo - Who we are">
        <h2>Cultures and values</h2>
        <p>We have four shared values that are embedded throughout all that we do:</p>
        <ul>
          <li>Crack it together</li>
          <li>Think next need</li>
          <li>Start with why</li>
          <li>Try it out</li>
        </ul>
      </Hero>

      <Awards awards={awards} />
    </Layout>
  );
};

export const query = graphql`
  query WhoWeAreQuery {
    allPrismicAwards(sort: { fields: data___order, order: DESC }) {
      nodes {
        data {
          award_year
          award_title {
            raw
          }
          award_logo {
            gatsbyImageData
          }
        }
      }
    }
    whoWeAreHero: file(relativePath: { eq: "who-we-are-page-banner.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000)
      }
    }
  }
`;

export default WhoWeAre;
