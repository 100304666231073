import React from "react";
import Carousel from "../components/carousel";

const History = () => {
  const history = [
    <p>
      <strong>1977</strong> | MotoNovo Finance was founded as Carlyle Finance in Cardiff, South Wales, by Sir Julian
      Hodge.
    </p>,
    <p>
      <strong>2000</strong> | Under new leadership, the business set about a major change programme with a clear vision
      to become the UK’s leading independent motor finance business. An absolute focus on dealer finance, a real
      entrepreneurial spirit, and a willingness to embrace change and innovation enabled the company to grow in scale,
      reputation and profitability. At a time of exits and consolidations in the independent finance sector, we
      continued to win friends amongst the dealer community.
    </p>,
    <p>
      <strong>2006</strong> | WesBank, South Africa's leading car finance company, acquired the business.
    </p>,
    <p>
      <strong>2008</strong> | The Banking Crisis presented an opportunity for Carlyle Finance to grow out of even the
      darkest days. The concept of inch by inch improvement inspired the business to celebrate customer service, the
      small wins, and operating with integrity.
    </p>,
    <p>
      <strong>2011</strong> | Just as bankers were being let back into public trust, the PPI scandal gripped the UK. Yet
      again, mistrust and dishonesty plagued the financial markets and banks were back out in the cold with the British
      public. We saw the opportunity and pushed forward with a way of making PPI very customer friendly and continued to
      sell it profitably.
    </p>,
    <p>
      <strong>2012</strong> | Carlyle Finance rebrands as MotoNovo Finance. After years of galvanising success, the
      rebrand helps distinguish the company from its competitors as a driving force for change in the industry, helping
      dealers connect with customers to sell more cars and more finance in a challenging market. The MotoNovo brand
      fully embraces its Latin translation, where ‘moto’ means ‘moving, driving forward’ and ‘novo’ means ‘refresh,
      renew, innovate, change’.
    </p>,
    <p>
      <strong>2014</strong> | Our quest to take position as one of the UK’s top performing finance companies was
      achieved when MotoNovo became third place market position.
    </p>,
    <p>
      <strong>2016</strong> |We moved to our new headquarters at One Central Square, in the heart of Cardiff City
      Centre.
    </p>,
    <p>
      <strong>2017</strong> | MotoNovo achieved second place market position, displacing Santander, and continued to
      expand across the UK and Northern Ireland.
    </p>,
    <p>
      <strong>2018</strong> | Our parent company FirstRand acquired British retail bank, Aldermore, beginning the
      integration process. We also launched the MotoNovo Commercial division, providing business-specific products and
      dedicated consultants to commercial customers.
    </p>,
    <p>
      <strong>2019</strong> | MotoNovo takes another big step in its continuing journey, trading as MotoNovo Finance
      Limited, part of the Aldermore Group. By combining with Aldermore we will now be able to unlock even more
      potential in delivering for our dealers and customers. We’re looking forward to the next stage of our exciting
      journey as part of the Aldermore group.
    </p>,
  ];

  return (
    <section className="block grid-container" aria-labelledby="#ourHistory">
      <h2 id="ourHistory" className="block-intro text-center">
        Our history
      </h2>

      <Carousel
        align="center"
        show_navigation
        slides={history.map((item, idx) => (
          <div key={idx} className="grid-container narrow text-center">
            {item}
          </div>
        ))}
      />
    </section>
  );
};
export default History;
