import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import MnfAccordion from "../components/mnf-accordion";
const Awards = ({ awards }) => {
  const year_awards = {};

  awards.forEach((award) => {
    if (!year_awards.hasOwnProperty(award.data.award_year)) {
      year_awards[award.data.award_year] = [];
    }

    year_awards[award.data.award_year].push({
      title: award.data.award_title.raw[0].text,
      image: award.data.award_logo,
    });
  });

  return (
    <section className="block grid-container medium" aria-labelledby="ourAwards">
      <h2 id="ourAwards" className="block-intro text-center">
        Our awards
      </h2>

      <MnfAccordion
        items={Object.keys(year_awards)
          .sort((a, b) => b - a)
          .map((year, idx) => ({
            title: year,
            content: (
              <div className="awards-grid">
                {year_awards[year].map((award, idx) => (
                  <div className="award-hover" key={idx}>
                    <GatsbyImage image={getImage(award.image)} alt={award.title} />
                    <div className="overlay">
                      <p>{award.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            ),
          }))}
      />
    </section>
  );
};
export default Awards;
