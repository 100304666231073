import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Hero = ({ image, alt, children }) => {
  const src = image ? getImage(image) : false;
  return (
    <div className="hero block">
      {src && <GatsbyImage image={src} alt={alt} />}

      <div className="overlay">{children}</div>
    </div>
  );
};
export default Hero;
